import { useLocation, useNavigate } from "react-router-dom";
import { RoutingContext } from "../context/RoutingContext";
import AuthRoutes from "./AuthRoutes";
import React, { useContext, useEffect } from "react";
import { UserContext } from "../context/UserContext";
import CaregiverRoutes from "./CaregiverApp";
import ScreenerRoutes from "./ScreenerRoutes";
import ProviderApp from "./ProviderApp";

export default function AppRouter() {
  const { authenticated, user, loading, loginInProgress } = useContext(UserContext) || {};
  const { getRedirect, setRedirect, routeRequiresAuth } = useContext(RoutingContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!loading && !loginInProgress) {
      if (authenticated && user) {
        const redirect = getRedirect();

        if (redirect) {
          setRedirect(undefined);
          navigate(redirect);
        } else if (location.pathname === "/" || location.pathname === "/login") {
          navigate("/");
        }
      } else if (!authenticated) {
        if (routeRequiresAuth(location.pathname) && location.pathname !== "/" && location.pathname !== "/login") {
          setRedirect(`${location.pathname}${location.search}`);
          navigate("/login");
        }
      }
    }
  }, [
    authenticated,
    getRedirect,
    loading,
    location?.pathname,
    location?.search,
    loginInProgress,
    navigate,
    routeRequiresAuth,
    setRedirect,
    user
  ]);

  return (
    <>
      <CaregiverRoutes renderIf={!!authenticated && user?.accountType === "PARENT"} />
      <ProviderApp renderIf={!!authenticated && !!user?.accountType && user.accountType !== "PARENT"} />
      <ScreenerRoutes />
      <AuthRoutes />
    </>
  );
}
