import path from "path";
import React, { ReactNode, useCallback } from "react";
import { createRoutesFromElements, matchPath, RouteObject } from "react-router-dom";

type Context = {
  getRedirect: () => string | null;
  setRedirect: (redirect: string | undefined) => void;
  registerRoutes: (routeElements: ReactNode, options?: { authRequired?: boolean }) => { remove: () => void };
  routesExistsForPath: (path: string) => boolean;
  routeRequiresAuth: (path: string) => boolean;
};

export const RoutingContext = React.createContext<Context>({} as Context);

const redirectKey = "app-redirect-pathname";

export function RoutingContextProvider({ children }) {
  const [authRequiredRoutes, setAuthRequiredRoutes] = React.useState<RouteObject[]>([]);
  const [allRoutes, setAllRoutes] = React.useState<RouteObject[]>([]);

  const getRedirect = useCallback(() => {
    return window.sessionStorage.getItem(redirectKey);
  }, []);

  const setRedirect = useCallback(redirect => {
    if (redirect) {
      window.sessionStorage.setItem(redirectKey, redirect);
    } else {
      window.sessionStorage.removeItem(redirectKey);
    }
  }, []);

  const registerRoutes = useCallback((routeElements: ReactNode, options?: { authRequired?: boolean }) => {
    const routes = createRoutesFromElements(routeElements);
    if (options?.authRequired) {
      setAuthRequiredRoutes(authRoutes => [...authRoutes, ...routes]);
    }
    setAllRoutes(allRoutes => [...allRoutes, ...routes]);

    return {
      remove: () => {
        setAllRoutes(allRoutes => allRoutes.filter(route => !routes.includes(route)));
        if (options?.authRequired) {
          setAuthRequiredRoutes(authRoutes => authRoutes.filter(route => !routes.includes(route)));
        }
      }
    };
  }, []);

  const routesExistsForPath = useCallback(
    (path: string) => {
      return allRoutes.some(r => r.path && matchPath(r.path, path));
    },
    [allRoutes]
  );

  const routeRequiresAuth = useCallback(
    (path: string) => {
      return authRequiredRoutes.some(r => r.path && matchPath(r.path, path));
    },
    [authRequiredRoutes]
  );

  return (
    <RoutingContext.Provider
      value={{ getRedirect, setRedirect, registerRoutes, routesExistsForPath, routeRequiresAuth }}
    >
      {children}
    </RoutingContext.Provider>
  );
}
