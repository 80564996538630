import React, { useState, useEffect, useMemo } from "react";
import "./MatchProviders.css";
import useCareTeam from "../hooks/useCareTeam";
import Button from "./Button";
import { orderBy } from "lodash";
import { User } from "../model/userModels";
import { useMutation, useQuery } from "@apollo/client";
import { GET_CARE_TEAM_MATCHES, SET_CARE_TEAM_MEMBER_MATCHES } from "../queries";
import { useGlobalContext } from "../context/GlobalComponentsContext";
import Spinner from "./Spinner";
import { useActions } from "tiny-atom/react/hooks";
import ButtonStack from "./ButtonStack";
import { reportError } from "../utils/errorUtils";

const baseUrl =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000/schedule-intake?clientId="
    : "https://home.getmanatee.com/schedule-intake?clientId=";

function generateIntakeLink(clientId): string {
  return baseUrl + `${clientId}`;
}

export default function MatchProviders({ clientId }) {
  const { showError } = useGlobalContext();
  const [selectedProviders, setSelectedProviders] = useState<User[]>([]);
  const [copySuccess, setCopySuccess] = useState(false);
  const careTeam = useCareTeam();
  const [processing, setProcessing] = useState(false);
  const { displayToast } = useActions();

  const {
    data,
    loading: loadingMatches,
    error,
    refetch
  } = useQuery(GET_CARE_TEAM_MATCHES, {
    variables: { userIds: [clientId] },
    fetchPolicy: "no-cache"
  });

  const loading = loadingMatches || processing;

  useEffect(() => {
    if (error) {
      reportError(error);
      showError();
    }
  }, [error, showError]);

  const [setCareTeamMemberMatchesMutation] = useMutation(SET_CARE_TEAM_MEMBER_MATCHES);

  const handleProviderSelect = provider => {
    if (selectedProviders.some(p => p.id === provider.id)) {
      setSelectedProviders(selectedProviders.filter(p => p.id !== provider.id));
    } else if (selectedProviders.length < 3) {
      setSelectedProviders([...selectedProviders, provider]);
    }
  };

  const therapists = useMemo(() => {
    return orderBy(careTeam.therapists || [], [item => item.firstName.toLowerCase()], ["asc"]);
  }, [careTeam]);

  useEffect(() => {
    if (data) {
      setSelectedProviders(
        therapists.filter(provider => data.careTeamMemberMatches.some(match => match.careTeamMember.id === provider.id))
      );
    }
  }, [data, therapists]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (copySuccess) {
      timeoutId = setTimeout(() => {
        setCopySuccess(false);
      }, 3000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [copySuccess]);

  return (
    <div className="provider-matching-container">
      <p>Select up to 3 providers from the list below to generate an intake scheduling link.</p>
      <ul className="provider-list">
        {loading ? (
          <Spinner inline />
        ) : (
          <>
            {therapists.map(provider => (
              <li key={provider.id}>
                <input
                  type="checkbox"
                  id={provider.id.toString()}
                  checked={selectedProviders.some(p => p.id === provider.id)}
                  onChange={() => handleProviderSelect(provider)}
                  disabled={!selectedProviders.some(p => p.id === provider.id) && selectedProviders.length >= 3}
                />
                <label htmlFor={provider.id.toString()}>{getFullName(provider)}</label>
              </li>
            ))}
          </>
        )}
      </ul>

      <div className="selected-providers">
        <strong>Selected providers:</strong>
        <br />
        {selectedProviders.map(p => getFullName(p)).join(", ") || "None"}
      </div>

      <div className="intake-link">
        <strong>Intake scheduling link</strong>
        <p>{generateIntakeLink(clientId)}</p>
        <ButtonStack>
          <Button
            theme="gray"
            title={copySuccess ? "Copied!" : "Copy to clipboard"}
            onPress={() => {
              navigator.clipboard.writeText(generateIntakeLink(clientId)).then(() => {
                setCopySuccess(true);
              });
            }}
          />
          <Button
            theme="manaBlue"
            title="Save"
            disabled={loading}
            loading={loading}
            onPress={async () => {
              try {
                setProcessing(true);
                await setCareTeamMemberMatchesMutation({
                  variables: {
                    userId: clientId,
                    careTeamMemberIds: selectedProviders.map(p => p.id),
                    retainCareCoordinatorMatches: true
                  }
                }).then(() => refetch());
                displayToast({ text: "Matched providers saved" });
              } catch (error) {
                showError();
              } finally {
                setProcessing(false);
              }
            }}
          />
        </ButtonStack>
      </div>
    </div>
  );
}

function getFullName(user: User): string {
  return `${user.firstName} ${user.lastName}`;
}
