import { ReactNode, useContext, useEffect } from "react";
import { RoutingContext } from "../../context/RoutingContext";

export default function useAuthRequiredRouting(routeElements: ReactNode) {
  const { registerRoutes } = useContext(RoutingContext);

  useEffect(() => {
    const subscription = registerRoutes(routeElements, { authRequired: true });
    return subscription.remove;
  }, [registerRoutes, routeElements]);
}
