import React, { ReactNode, useMemo, useRef } from "react";
import Sidebar from "../components/Sidebar";
import "./ProviderApp.css";
import ToastList from "../components/ToastList";

import { SideBarContextProvider } from "../context/SideBarContext";
import { AppContextProvider } from "../context/AppContext";
import { MessagingContextProvider } from "../context/MessagingContext";
import { Route, Routes } from "react-router-dom";
import Directory from "../pages/Directory";
import Patient from "../pages/Patient";
import Invite from "../pages/Invite";
import InviteDetails from "../pages/InviteDetails";
import PendingInvites from "../pages/PendingInvites";
import Connection from "../pages/Connection";
import AddConnection from "../pages/AddConnection";
import RemoveConnection from "../pages/RemoveConnection";
import MyInbox from "../pages/MyInbox";
import useAuthRequiredRouting from "./hooks/useAuthRequiredRouting";
import WhenRouteMatched from "./WhenRouteMatched";

export default function ProviderApp({ renderIf }: { renderIf: boolean }) {
  const sideBarRef = useRef();

  const routeElements: ReactNode = useMemo(
    () => (
      <>
        <Route path="/" element={<Directory />} />
        <Route path="/patients" element={<Directory />} />
        <Route path="/patient" element={<Patient />} />
        <Route path="/patient/:patientId" element={<Patient />} />
        <Route path="/patient/:patientId/caregivers/:caregiverId/conversations/:conversationId" element={<Patient />} />
        <Route path="/patient/:patientId/conversations/:conversationId" element={<Patient />} />
        <Route path="/patient/:patientId/assessment" element={<Patient />} />
        <Route path="/invite" element={<Invite />} />
        <Route path="/inviteDetails" element={<InviteDetails />} />
        <Route path="/pendingInvites" element={<PendingInvites />} />
        <Route path="/connection" element={<Connection />} />
        <Route path="/addConnection" element={<AddConnection />} />
        <Route path="/removeConnection" element={<RemoveConnection />} />
        <Route path="/myInbox" element={<MyInbox />} />
        <Route path="/conversations/:conversationId" element={<MyInbox />} />
      </>
    ),
    []
  );

  useAuthRequiredRouting(routeElements);

  return (
    <>
      {renderIf && (
        <WhenRouteMatched routeElements={routeElements}>
          <div className="Chrome">
            <AppContextProvider>
              <MessagingContextProvider>
                <Sidebar ref={sideBarRef} />
                <SideBarContextProvider sideBarRef={sideBarRef}>
                  <div className="Chrome-content">
                    <Routes>{routeElements}</Routes>
                    <ToastList />
                  </div>
                </SideBarContextProvider>
              </MessagingContextProvider>
            </AppContextProvider>
          </div>
        </WhenRouteMatched>
      )}
    </>
  );
}
