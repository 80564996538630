import { Route, Routes } from "react-router-dom";
import ScheduleAppointment from "../pages/ScheduleAppointment";
import React, { ReactNode, useContext, useMemo } from "react";
import WhenRouteMatched from "./WhenRouteMatched";
import useAuthRequiredRouting from "./hooks/useAuthRequiredRouting";
import ScheduleIntake from "../pages/ScheduleIntake";
import { UserContext } from "../context/UserContext";

export default function CaregiverRoutes({ renderIf }: { renderIf: boolean }) {
  const { authenticated } = useContext(UserContext) || {};

  const routes: ReactNode = useMemo(
    () => (
      <>
        {authenticated && <Route path="/" element={<ScheduleIntake />} />}
        <Route path="/schedule-appointment" element={<ScheduleAppointment />} />
        <Route path="/schedule-intake" element={<ScheduleIntake />} />
      </>
    ),
    [authenticated]
  );
  useAuthRequiredRouting(routes);

  return <>{renderIf && <WhenRouteMatched routeElements={routes}>{<Routes>{routes}</Routes>}</WhenRouteMatched>}</>;
}
