import React, { useLayoutEffect, useState } from "react";
import "./Forms.css";
import AuthService from "../services/AuthService";
import CompleteForms from "../components/forms/CompleteForms";
import ToastList from "../components/ToastList";
import FormsCompleted from "./FormsCompleted";
import { useSearchParams } from "react-router-dom";

export default function Forms() {
  const [routeSearchParams] = useSearchParams();
  const [authenticated, setAuthenticated] = useState(false);
  const [showError, setShowError] = useState(false);

  const ids = routeSearchParams.get("ids");
  const token = routeSearchParams.get("token");
  const completed = routeSearchParams.get("completed");

  const formIds = ids ? JSON.parse(decodeURIComponent(ids)) : null;

  useLayoutEffect(() => {
    if (token) {
      const decoded = decodeURIComponent(token);
      AuthService.setAuthToken(decoded);
      setAuthenticated(true);
    } else if (!completed) {
      setShowError(true);
    }
  }, [completed, token]);

  if (completed) {
    return (
      <div className="page-container">
        <FormsCompleted />
      </div>
    );
  }

  return (
    <div className="page-container">
      {authenticated && <CompleteForms formIds={formIds} />}
      {showError && <p className="message-title">Something went wrong... please go back and try again</p>}
      <ToastList />
    </div>
  );
}
