import React from "react";
import CareScreener from "../components/care-screener/CareScreener";

const TYPEFORM_ID = process.env.REACT_APP_CONSULT_TYPE_FORM_ID as string;
const TYPEFORM_PATH = "consult";
const CONTINUE_TO_BOOK_CONSULT_QUESTION_REF = "phoneNumber-fieldRef";

export default function ConsultPage() {
  return (
    <CareScreener
      typeformId={TYPEFORM_ID}
      typeformPath={TYPEFORM_PATH}
      allowAppointmentScheduling={true}
      accountCreationPredicate={{ tfQuestionRefShown: CONTINUE_TO_BOOK_CONSULT_QUESTION_REF }}
      insuranceEligiblityCheckEnabled={true}
    />
  );
}
