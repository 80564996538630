import React, { useContext, useState } from "react";
import { BlockStack, TextField, Button } from "@shopify/polaris";
import { Formik } from "formik";
import "./Login.css";
import { useAtom, useActions } from "tiny-atom/react/hooks";
import AuthService from "../services/AuthService";
import Modal from "../components/Modal";
import FormErrorMessage from "../components/FormErrorMessage";
import { UserContext } from "../context/UserContext";
import { RoutingContext } from "../context/RoutingContext";

const CLIENT_ACCOUNT_TYPES = new Set(["PARENT", "CHILD"]);

export default function Login() {
  const { navigate, logEvent, setCurrentScreen, navigate2FA, showModal, hideModal } = useActions();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [loading, setLoading] = useState(false);
  const error = useAtom(state => state.error);
  const isErrorModalVisible = useAtom(state => state.isErrorModalVisible);
  const { setRedirect } = useContext(RoutingContext);
  const { setLoginInProgress } = useContext(UserContext) || {};

  async function handleSubmit() {
    try {
      setLoading(true);
      setLoginInProgress(true);

      const user = await AuthService.login({
        application: "WEB",
        username: email,
        password: password
      });
      logEvent("webLoginSuccess");
      const authyStatus = sessionStorage.getItem("authyStatus");

      if (!CLIENT_ACCOUNT_TYPES.has(user?.accountType)) {
        if (authyStatus === "missing" && email !== "therapist@getmanatee.com") {
          setRedirect(undefined);
          navigate2FA({
            pathname: "/twoFactorAuthPhone",
            params: { email, password }
          });
        } else if (authyStatus === "expired" && email !== "therapist@getmanatee.com") {
          setRedirect(undefined);
          navigate2FA({
            pathname: "/twoFactorAuthSubmitCode",
            params: { email, password }
          });
        }
      }
    } catch (e) {
      let message = "Error logging in";
      if (e && e.message) {
        message = e.message.replace("Error: ", "");
      }
      showModal(message);
      logEvent("webLoginFailed");
    } finally {
      setLoading(false);
    }
  }

  setCurrentScreen("WebLoginScreen");
  return (
    <div className="Login">
      <div
        style={{
          pointerEvents: isErrorModalVisible ? "none" : undefined
        }}
      >
        <Formik
          initialValues={{ email: "", password: "" }}
          validate={() => {
            let errors = {};
            if (!email) {
              errors.email = "Required";
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
              errors.email = "Invalid email address";
            } else if (!password) {
              errors.password = "Password Required";
            }
            return errors;
          }}
          onSubmit={() => {
            if (!loading) {
              handleSubmit();
            }
          }}
        >
          {({ handleSubmit }) => (
            <div>
              <form
                className="Login-form"
                onKeyDown={e => {
                  if (e.key === "Enter" && !loading) {
                    handleSubmit();
                  }
                }}
              >
                <div className="Login-logo" />
                <BlockStack gap={"200"}>
                  <TextField placeholder="Email" onChange={setEmail} type="email" name="email" value={email} />
                  <FormErrorMessage name="email" component="div" />
                  <TextField
                    placeholder="Password"
                    onChange={setPassword}
                    type="password"
                    name="password"
                    value={password}
                  />
                  <FormErrorMessage name="password" component="div" />
                  <Button fullWidth variant="primary" submit disabled={loading} onClick={handleSubmit}>
                    Login
                  </Button>
                  <div className="Login-forgot">
                    Don&apos;t have an account?{" "}
                    <a href="/register" onClick={() => navigate({ pathname: "/register" })}>
                      Sign up
                    </a>
                  </div>
                  <div className="Login-forgot">
                    Forgot your password?{" "}
                    <a href="/forgotPassword" onClick={() => navigate({ pathname: "/forgotPassword" })}>
                      Reset
                    </a>
                  </div>
                </BlockStack>
              </form>
            </div>
          )}
        </Formik>
      </div>
      <Modal
        title={error ? error.message : ""}
        hidden={!isErrorModalVisible}
        onButton1Press={hideModal}
        fullscreen={true}
      />
    </div>
  );
}
