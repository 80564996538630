import React, { useContext, useState } from "react";
import AppointmentDayPicker from "./AppointmentDayPicker";
import { AppointmentSchedulerContext } from "./AppointmentScheduler";
import AppointmentTimePicker from "./AppointmentTimePicker";
import Text from "../core/Text";
import { UserContext } from "../../context/UserContext";
import Flex from "../core/Flex";

function AppointmentDateTimePicker({ onContinuePressed, headerComponent }) {
  const [selectedDay, setSelectedDay] = useState(new Date());
  const { user } = useContext(UserContext);
  const { appointmentType, attendee } = useContext(AppointmentSchedulerContext);

  return (
    <Flex flex center fullWidth>
      <Flex flex style={{ alignSelf: "center" }}>
        {headerComponent}
        <Text
          center
          theme="manaBlue"
          size={16}
          weight={700}
          style={{ alignSelf: "center", paddingTop: 20, paddingBottom: 40, width: 350 }}
        >
          Pick a date and time for {attendee?.id === user?.id ? "your" : `${attendee?.firstName}'s`}{" "}
          {appointmentType?.name || "session"}.
        </Text>
        <Flex center>
          <AppointmentDayPicker selectedDay={selectedDay} setSelectedDay={setSelectedDay} />
        </Flex>
      </Flex>
      <Flex center style={{ width: 420, maxWidth: "85vw" }} row flexGrow>
        <AppointmentTimePicker selectedDay={selectedDay} onContinuePressed={onContinuePressed} />
      </Flex>
    </Flex>
  );
}

export default AppointmentDateTimePicker;
