import React from "react";
import FamilyImage from "../../assets/consult/consult-booked-illustration.png";
import { StyleSheet, css } from "aphrodite";
import Text from "../core/Text";
import Flex from "../core/Flex";
import { User } from "../../model/userModels";
import Span from "../core/Span";
import useCareScreenerWidth from "../../hooks/care-screener/useCareScreenerWidth";
import TimelineImage from "../../assets/care-screener/intake-timeline.png";

type Props = {
  careCoordinator: User;
  onBookConsultPressed: () => void;
};

export default function DirectIntakeRequested({ careCoordinator, onBookConsultPressed }: Props) {
  const { widthStyle } = useCareScreenerWidth();
  const [showProfileImage, setShowProfileImage] = React.useState(true);

  const profileImageFolder = process.env.NODE_ENV === "development" ? "test" : careCoordinator.id;
  const profileImageUrl = `https://storage.googleapis.com/manatee-production-app-content/images/profiles/${profileImageFolder}/profile.png`;

  return (
    <Flex className={css(styles.container)} style={widthStyle}>
      <Flex center>
        <img src={FamilyImage} className={css(styles.image)} />
        <Text title center>
          We're working on your match!
        </Text>
        <img src={TimelineImage} style={{ width: "100%", maxWidth: 300, marginTop: 20, objectFit: "contain" }} />
      </Flex>
      <Text center style={{ marginTop: 20, marginBottom: 0 }}>
        <Span theme="manaBlueDark" weight={700}>
          98% of families
        </Span>{" "}
        love their first match. And on average, most families complete their intakes within a week.
      </Text>
      <Flex style={{ backgroundColor: "#F2F2F2", marginTop: 20, padding: 20, borderRadius: 15 }}>
        <Flex row>
          {showProfileImage && (
            <img
              onError={() => setShowProfileImage(false)}
              src={profileImageUrl}
              className={css(styles.profileImage)}
              alt="careCoordinatorProfile"
            />
          )}
          <Flex center left style={{ paddingLeft: 10 }}>
            <>
              {careCoordinator.firstName && (
                <Text weight={700} size={16} theme="manaBlueDark">
                  {careCoordinator.firstName}
                </Text>
              )}
              <Text weight={400} size={14} theme="manaBlueDark">
                Care coordinator
              </Text>
            </>
          </Flex>
        </Flex>
        {!!careCoordinator && (
          <>
            <Text weight={400} style={{ paddingTop: 10 }}>
              Hi! Welcome to Manatee! I just received all your info, so I'll be working on:
            </Text>
            <Text weight={400} style={{ lineHeight: 1.4 }}>
              <ul style={{ marginLeft: -10 }}>
                <li>Reviewing your family's needs</li>
                <li>Handpicking therapists who match your family best</li>
                <li>Reviewing your payment options or insurance with our Billing team</li>
              </ul>
            </Text>
            <Text weight={400}>
              Check your inbox for an email from{" "}
              <Span weight={700} theme="manaBlueDark">
                welcome@getmanatee.com
              </Span>{" "}
              to get started!
            </Text>
          </>
        )}
        <Flex
          fullWidth
          center
          style={{
            borderTop: "solid",
            borderTopWidth: 2,
            borderTopColor: "#E0E0E0",
            marginTop: 20,
            paddingTop: 20,
            paddingBottom: 10
          }}
        >
          <Text weight={700} center theme="manaBlueDark">
            Need anything in the meantime?
          </Text>
          <Text
            center
            link={{
              onPress: onBookConsultPressed
            }}
          >
            Book a free phone consult.
          </Text>
          <Text center weight={400}>
            or text me at (213) 558-4348.
          </Text>
        </Flex>
      </Flex>
      <Flex fullWidth center style={{ padding: 20 }}>
        <Text center>Want to know more?</Text>
        <Text
          center
          link={{
            onPress: () => {
              window.open("https://www.getmanatee.com/faqs", "_blank");
            }
          }}
        >
          Read our FAQs
        </Text>
      </Flex>
    </Flex>
  );
}

function Faq({ question, children }: { question: string; children?: React.ReactNode }) {
  return (
    <Flex style={{ paddingBottom: 20 }}>
      <Text size={24} subtitle weight={400} style={{ paddingTop: 20, paddingBottom: 20 }}>
        {question}
      </Text>
      {children}
    </Flex>
  );
}

function Statistic({ percent, description }) {
  return (
    <Flex row style={{ paddingBottom: 10 }}>
      <Text title size={40} style={{ minWidth: 100 }}>
        {percent}
      </Text>
      <Text body>{description}</Text>
    </Flex>
  );
}

const styles = StyleSheet.create({
  container: {
    marginTop: 50,
    marginBottom: 50,
    padding: "5%",
    paddingTop: 10,
    backgroundColor: "#FFFFFF",
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: 15
  },
  image: {
    width: 225,
    height: 225,
    objectFit: "contain"
  },
  profileImage: {
    width: 60,
    height: 60,
    objectFit: "cover",
    borderRadius: 60
  }
});
